import { FC } from 'react'
import * as s from './Icon.styled'
import { TIconProps } from './Icon.types'

export const Share: FC<TIconProps> = ({ size }) => {
  return <s.SVG viewBox="0 0 48 60" version="1.1" x="0px" y="0px" style={{ width: size, height: size }}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fillRule="nonzero" fill="#000000">
        <g>
          <path
            d="M26,7.83753887 L26,15 L38.0098214,15 C40.2135362,15 42,16.7890275 42,18.9881206 L42,43.0118794 C42,45.2144576 40.2147544,47 38.0098214,47 L9.99017859,47 C7.7864638,47 6,45.2109725 6,43.0118794 L6,18.9881206 C6,16.7855424 7.7852456,15 9.99017859,15 L22,15 L22,7.8310591 L19.7410775,10.0899816 C18.9672318,10.8638273 17.7121862,10.8634336 16.9256923,10.0769397 C16.1446437,9.29589109 16.149254,8.02495089 16.9126504,7.26155447 L22.5955885,1.57861638 C22.9803726,1.19383221 23.4841305,1.00047404 23.990101,1 L24.0053662,1.00597076 C24.5132928,1.00705432 25.0183527,1.19903734 25.3979318,1.57861638 L31.0808699,7.26155447 C31.8547156,8.0354002 31.8543218,9.29044576 31.0678279,10.0769397 C30.2867793,10.8579883 29.0158392,10.853378 28.2524427,10.0899816 L26,7.83753887 Z M22,19 L10,19 L10,43 L38,43 L38,19 L26,19 L26,27.0029953 C26,28.1059106 25.1122704,29 24,29 C22.8954305,29 22,28.1050211 22,27.0029953 L22,19 Z M16,15 L16,19 L32,19 L32,15 L16,15 Z M22,15 L26,15 L26,19 L22,19 L22,15 Z" />
        </g>
      </g>
    </g>
  </s.SVG>
}